import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { TClients, TDeals } from '../model/types';
import { IMeta } from '../../../interfaces';

export const getClients = async (): Promise<TClients> => {
  try {
    const { data } = await axios.post<{
      data: TDeals[];
      meta: IMeta;
    }>(`${API_URL}/exchange-point-offer-requests/search`, {
      limit: 10000,
      page: 1,
    });

    const clients = data?.data.map((el) => el.nickname as string);

    const uniqueClients = clients.filter((value, index, self) => {
      return self.indexOf(value) === index && !!value;
    });

    return { arr: uniqueClients, type: 'clients' };
  } catch {
    return { arr: [], type: 'clients' };
  }
};
