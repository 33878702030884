import create from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  IClient,
  IExchangePoint,
  IOperationType,
  IUserAccount,
} from '../../../interfaces';
import { produce } from 'immer';
import { axios } from '../../exios';
import { API_URL } from '../../../packages/keycloak-client/constants';

interface IOperationsFiltersState {
  filterId: number;
  filters: IOperationsFilters;
  rawFilters: IOperationsRawFilters;
  isTemporaryFilters: boolean;
  isFiltersUpdated: boolean;
  filtersOptions: IOperationsFiltersOption;
  setOperationsFilters: (
    filters: IOperationsRawFilters,
    isTemporaryFilters: boolean
  ) => void;
  setOperationsFilter: (key: TOperationsFiltersKey, value: any) => void;
  deleteOperationsFilter: (
    key: TOperationsFiltersKey,
    value: boolean | string | number
  ) => void;
  getOperationsFiltersOptions: () => Promise<void>;
  getBackendFilters: () => void;
  resetFilters: () => void;
  resetRawFilters: () => void;
  clearFilters: () => void;
  saveFilters: (filters: IOperationsFilters) => Promise<void>;
}

export type DataValue = (string | null)[];

export interface IFilterOption {
  value?: number | string;
  label: string;
  key: number | string;
  options?: IFilterOption[];
}

export interface IOperationsFilters {
  operations_chain_type_id?: IFilterOption[];
  status?: IFilterOption[];
  operation_created_at?: DataValue;
  first_exchange_point_id?: IFilterOption[];
  initiator_user_account_uid?: IFilterOption[];
  client_id?: IFilterOption[];
  rate_benefit?: boolean;
}

export interface IOperationsRawFilters {
  id?: number;
  operations_chain_type_id?: number[];
  mmf_codes?: number[];
  creation_date_from?: string | null;
  creation_date_to?: string | null;
  status?: string[];
  operation_created_at?: DataValue | null;
  first_exchange_point_id?: number[];
  initiator_user_account_uid?: string[];
  client_id?: number[];
  rate_benefit?: boolean;
}

export interface IOperationsFiltersOption {
  operations_chain_type_id: IFilterOption[];
  status: IFilterOption[];
  first_exchange_point_id: IFilterOption[];
  initiator_user_account_uid: IFilterOption[];
  client_id: IFilterOption[];
}

export type TOperationsFiltersKey = keyof IOperationsFilters;
export type TOperationsFiltersValue =
  | (string | null | IFilterOption)[]
  | boolean;

export const useOperationsFiltersState = create<IOperationsFiltersState>()(
  devtools(
    (set, get) => ({
      filterId: 0,
      filters: {},
      rawFilters: {},
      isTemporaryFilters: false,
      isFiltersUpdated: false,
      filtersOptions: {
        operations_chain_type_id: [],
        status: [
          {
            label: 'waiting',
            value: 'waiting',
            key: 'waiting',
          },
          {
            label: 'closed',
            value: 'closed',
            key: 'closed',
          },
          {
            label: 'canceled',
            value: 'canceled',
            key: 'canceled',
          },
        ],
        first_exchange_point_id: [],
        initiator_user_account_uid: [],
        client_id: [],
      },
      setOperationsFilters: (
        filters: IOperationsRawFilters,
        isTemporaryFilters: boolean
      ) => {
        set(
          produce((draft) => {
            draft.filters = {};
            draft.rawFilters = filters;
            draft.isTemporaryFilters = isTemporaryFilters;
          }),
          false,
          {
            type: 'useOperationsFiltersState => setOperationsFilters',
          }
        );
      },
      setOperationsFilter: (key: TOperationsFiltersKey, value: any) => {
        set(
          produce((draft) => {
            if (typeof value === 'boolean') {
              if (value) {
                draft.filters[key] = true;
              } else {
                delete draft.filters[key];
              }
            } else if (Array.isArray(value)) {
              if (value.length) {
                draft.filters[key] = value;
              } else {
                delete draft.filters[key];
              }
            }
            draft.isFiltersUpdated = true;
          }),
          false,
          {
            type: 'useOperationsFiltersState => setOperationsFilter',
          }
        );
      },
      deleteOperationsFilter: (
        key: TOperationsFiltersKey,
        value: boolean | string | number
      ) => {
        set(
          produce((draft) => {
            if (typeof value === 'boolean') {
              delete draft.filters[key];
            } else if (key !== 'operation_created_at') {
              draft.filters[key] = draft.filters[key].filter(
                (item: IFilterOption) => item.value !== value
              );
              !draft.filters[key].length && delete draft.filters[key];
            } else {
              draft.filters[key] = draft.filters[key].map((item: string) => {
                if (item === value) {
                  return null;
                }
                return item;
              });
              !Object.values(draft.filters[key]).filter(
                // @ts-ignore
                (objValue: string | number | null) => objValue !== null
              ).length && delete draft.filters[key];
            }
            draft.isFiltersUpdated = true;
          }),
          false,
          {
            type: 'useOperationsFiltersState => setOperationsFilter',
          }
        );
      },
      getOperationsFiltersOptions: async () => {
        await axios
          .post<{ data: IOperationType[] }>(
            `${API_URL}/operations-chain-types/search`,
            {
              limit: 1000,
              includes: [{ relation: 'mmfCodes' }],
            }
          )
          .then((res) => {
            set(
              produce((draft) => {
                draft.filtersOptions.operations_chain_type_id =
                  res.data.data.map((item) => {
                    if (item.mmf_codes.length) {
                      return {
                        label: item.name,
                        key: 'operationType:' + item.id,
                        options: item.mmf_codes.map((mmf) => {
                          return {
                            value: 'mmf:' + mmf.id,
                            label: mmf.name,
                            key: 'mmf:' + mmf.id,
                          };
                        }),
                      };
                    }
                    return {
                      value: 'operationType:' + item.id,
                      label: item.name,
                      key: 'operationType:' + item.id,
                    };
                  });
                let newFilters: any[] = [];
                if (draft.rawFilters.operations_chain_type_id?.length) {
                  draft.rawFilters.operations_chain_type_id.forEach(
                    (operationId: number) => {
                      res.data.data.forEach((opType) => {
                        if (opType.id === operationId) {
                          newFilters.push({
                            value: 'operationType:' + opType.id,
                            label: opType.name,
                            key: 'operationType:' + opType.id,
                          });
                        }
                      });
                    }
                  );
                }
                if (draft.rawFilters.mmf_codes?.length) {
                  draft.rawFilters.mmf_codes.forEach((mmfId: number) => {
                    res.data.data.forEach((opType) => {
                      opType.name === 'mmf' &&
                        opType.mmf_codes.forEach((mmf) => {
                          if (mmf.id === mmfId) {
                            newFilters.push({
                              value: 'mmf:' + mmf.id,
                              label: mmf.name,
                              key: 'mmf:' + mmf.id,
                            });
                          }
                        });
                    });
                  });
                }
                newFilters.length &&
                  (draft.filters.operations_chain_type_id = newFilters);
              }),
              false,
              {
                type: 'useOperationsFiltersState => getOperationsFiltersOptions',
              }
            );
          });

        await axios
          .post<{ data: IExchangePoint[] }>(
            `${API_URL}/exchange-points/search`,
            {
              limit: 1000,
            }
          )
          .then((res) => {
            set(
              produce((draft) => {
                draft.filtersOptions.first_exchange_point_id =
                  res.data.data.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                      key: item.id,
                    };
                  });

                if (draft.rawFilters.first_exchange_point_id?.length) {
                  let newFilters: any[] = [];
                  draft.rawFilters.first_exchange_point_id.forEach(
                    (exId: number | string) => {
                      res.data.data.forEach((exPoint) => {
                        if (exPoint.id === exId) {
                          newFilters.push({
                            value: exPoint.id,
                            label: exPoint.name,
                            key: exPoint.id,
                          });
                        }
                      });
                    }
                  );
                  draft.filters.first_exchange_point_id = newFilters;
                }
              }),
              false,
              {
                type: 'useOperationsFiltersState => getOperationsFiltersOptions',
              }
            );
          });

        await axios
          .post<{ data: IUserAccount[] }>(`${API_URL}/user-accounts/search`, {
            limit: 1000,
          })
          .then((res) => {
            set(
              produce((draft) => {
                draft.filtersOptions.initiator_user_account_uid =
                  res.data.data.map((item) => {
                    return {
                      label: `${item.firstname} ${item.lastname}`,
                      value: item.uid,
                      key: item.uid,
                    };
                  });

                if (draft.rawFilters.initiator_user_account_uid?.length) {
                  let newFilters: any[] = [];
                  draft.rawFilters.initiator_user_account_uid.forEach(
                    (usAccId: number | string) => {
                      res.data.data.forEach((usAcc) => {
                        if (usAcc.uid === usAccId) {
                          newFilters.push({
                            value: usAcc.uid,
                            label: `${usAcc.firstname} ${usAcc.lastname}`,
                            key: usAcc.uid,
                          });
                        }
                      });
                    }
                  );
                  draft.filters.initiator_user_account_uid = newFilters;
                }
              }),
              false,
              {
                type: 'useOperationsFiltersState => getOperationsFiltersOptions',
              }
            );
          });

        await axios
          .post<{ data: IClient[] }>(`${API_URL}/clients/search`, {
            limit: 1000,
          })
          .then((res) => {
            set(
              produce((draft) => {
                draft.filtersOptions.client_id = res.data.data.map((item) => {
                  return {
                    label: item?.name || item?.comment || '<Noname>',
                    value: item.id,
                    key: item.id,
                  };
                });

                if (draft.rawFilters.client_id?.length) {
                  let newFilters: any[] = [];
                  draft.rawFilters.client_id.forEach(
                    (clId: number | string) => {
                      res.data.data.forEach((cl) => {
                        if (cl.id === clId) {
                          newFilters.push({
                            value: cl.id,
                            label: cl?.name || cl?.comment || '<Noname>',
                            key: cl.id,
                          });
                        }
                      });
                    }
                  );
                  draft.filters.client_id = newFilters;
                }
              }),
              false,
              {
                type: 'useOperationsFiltersState => getOperationsFiltersOptions',
              }
            );
          });

        set(
          produce((draft) => {
            if (
              draft.rawFilters.creation_date_from ||
              draft.rawFilters.creation_date_to
            ) {
              draft.filters.operation_created_at = [
                draft.rawFilters.creation_date_from,
                draft.rawFilters.creation_date_to,
              ];
            }
            if (draft.rawFilters.status?.length) {
              draft.filters.status = draft.rawFilters.status.map(
                (sts: string) => {
                  return {
                    value: sts,
                    label: sts,
                    key: sts,
                  };
                }
              );
            }
            if (draft.rawFilters.rate_benefit) {
              draft.filters.rate_benefit = draft.rawFilters.rate_benefit;
            }
            draft.isFiltersUpdated = false;
          })
        );
      },
      getBackendFilters: async () => {
        await axios
          .get<{ data: IOperationsRawFilters[] }>(
            `${API_URL}/filters-for-operation-chains`
          )
          .then((res) => {
            const data = res?.data?.data[0];
            set(
              produce((draft) => {
                draft.filterId = data?.id;
                if (!get().isTemporaryFilters) {
                  draft.rawFilters = data;
                }
              }),
              false,
              {
                type: 'useCFFiltersState => getBackendFilters',
              }
            );
            get().getOperationsFiltersOptions();
          })
          .catch((e) => {
            console.error('getBackendFilters error', e);
          });
      },
      saveFilters: async (filters: IOperationsFilters) => {
        await axios.put(
          `${API_URL}/filters-for-operation-chains/${get().filterId}`,
          formatToBackend(filters)
        );
      },
      clearFilters: () => {
        set(
          produce((draft) => {
            draft.filters = {};
            draft.isFiltersUpdated = true;
          }),
          false,
          {
            type: 'useOperationsFiltersState => clearFilters',
          }
        );
      },
      resetFilters: () => {
        set(
          produce((draft) => {
            draft.filters = {};
            draft.rawFilters = {};
            draft.isTemporaryFilters = false;
            draft.isFiltersUpdated = false;
          }),
          false,
          {
            type: 'useOperationsFiltersState => resetFilters',
          }
        );
      },
      resetRawFilters: () => {
        set(
          produce((draft) => {
            draft.rawFilters = {};
            draft.isTemporaryFilters = false;
            draft.isFiltersUpdated = false;
          }),
          false,
          {
            type: 'useOperationsFiltersState => resetRawFilters',
          }
        );
      },
    }),
    {
      anonymousActionType: 'useOperationsFiltersState action',
      name: 'operationsFiltersState',
    }
  )
);

const formatToBackend = (filters: IOperationsFilters) => {
  return {
    operations_chain_type_id:
      filters.operations_chain_type_id
        ?.filter(({ value }) => String(value)?.includes('operationType'))
        ?.map(({ value }) => +String(value)?.split(':')[1]) || [],
    mmf_codes:
      filters.operations_chain_type_id
        ?.filter(({ value }) => String(value)?.includes('mmf'))
        ?.map(({ value }) => +String(value)?.split(':')[1]) || [],
    first_exchange_point_id:
      filters.first_exchange_point_id?.map((item) => item.value) || [],
    status: filters.status?.map((item) => item.value) || [],
    rate_benefit: filters.rate_benefit || false,
    client_id: filters.client_id?.map((item) => item.value) || [],
    initiator_user_account_uid:
      filters.initiator_user_account_uid?.map((item) => item.value) || [],
    creation_date_from: filters.operation_created_at
      ? filters.operation_created_at[0]
      : null,
    creation_date_to: filters.operation_created_at
      ? filters.operation_created_at[1]
      : null,
  };
};
