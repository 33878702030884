import React, { Suspense, useMemo } from 'react';
import { Badge } from 'antd';
import styled from 'styled-components';
import { ErrorBoundary } from '../../../../ErrorBoundary';
import NotificationSubHeader from '../notification-sub-header/NotificationSubHeader';
import NotificationSelectHeader from '../notification-select-header/NotificationSelectHeader';
import NotificationSelectEmpty from '../NotificationSelectEmpty';
import NotificationSelectFooter from '../notification-select-footer/NotificationSelectFooter';
import { useSystemNotificationsBellState } from '../../../../../state/useSystemNotificationsBellState';
import { useTranslation } from 'react-i18next';

type TRenderTabsProps = {
  text: string;
  count: number;
};

const NotificationSelectDD = ({ item }: { item: any }) => {
  const { t, i18n } = useTranslation(['shared']);

  const currentTab = useSystemNotificationsBellState(
    (state) => state.currentTab
  );
  const setCurrentTab = useSystemNotificationsBellState(
    (state) => state.setCurrentTab
  );
  const getNotificationsCount = useSystemNotificationsBellState(
    (state) => state.getNotificationsCount
  );
  const corporateCount = useSystemNotificationsBellState(
    (state) => state.corporate.count
  );
  const myDealsCount = useSystemNotificationsBellState(
    (state) => state.my_deals.count
  );
  const totalCount = useSystemNotificationsBellState(
    (state) => state.totalCount
  );
  const getNotificationSearch = useSystemNotificationsBellState(
    (state) => state.getNotificationSearch
  );

  const handleTabClick = (key: any) => {
    setCurrentTab(key);
    getNotificationsCount();
    getNotificationSearch().then();
  };

  enum TabsEnum {
    All = 1,
    Deals = 2,
    Corp = 3,
  }

  const DefaultTab = TabsEnum.All;

  const itemStyles = {
    style: {
      boxShadow: 'none',
      borderTop: '1px solid #f0f0f0',
      borderBottom: '1px solid #f0f0f0',
      borderRadius: '0',
      background: '#14141F',
      width: '100%',
    },
  };

  const renderTabs = ({ text, count }: TRenderTabsProps) => {
    return (
      <>
        {text} <Badge count={count} color={'cyan'} showZero={true} />
      </>
    );
  };

  const tabs = useMemo(
    () => [
      {
        key: TabsEnum.All,
        label: renderTabs({ text: t('shared:тексты.Все'), count: totalCount }),
      },
      {
        key: TabsEnum.Deals,
        label: renderTabs({
          text: t('shared:тексты.Сделки'),
          count: myDealsCount,
        }),
      },
      {
        key: TabsEnum.Corp,
        label: renderTabs({
          text: t('shared:тексты.Корп точка уведомления'),
          count: corporateCount,
        }),
      },
    ],
    [totalCount, myDealsCount, corporateCount, currentTab, i18n.language]
  );

  return (
    <ErrorBoundary>
      <Suspense fallback={<>Loading...</>}>
        <NotificationSelectDDStyled>
          <NotificationSelectHeader />
          <NotificationSubHeader
            tabs={tabs}
            DefaultTab={DefaultTab}
            handleTabClick={handleTabClick}
          />
          {!!item?.props.items?.length ? (
            <NotificationSelectItemStyled>
              {React.cloneElement(item as React.ReactElement, itemStyles)}
            </NotificationSelectItemStyled>
          ) : (
            <NotificationSelectEmpty />
          )}
          <NotificationSelectFooter />
        </NotificationSelectDDStyled>
      </Suspense>
    </ErrorBoundary>
  );
};

const NotificationSelectDDStyled = styled.div`
  position: absolute;
  top: 17px;
  right: -730%;
  background: #14141f;
  padding: 0 1px 1px 1px;
  height: calc(100vh - 70px);
  width: 496px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &&& .ant-dropdown-menu {
    height: 100%;
  }
`;

const NotificationSelectItemStyled = styled.div`
  background: rgb(20, 20, 31);
  height: 67vh;

  .ant-dropdown-menu {
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
    padding: 0 !important;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #474755;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .ant-dropdown-menu-item {
    padding: 0 !important;
    //width: 100% !important;
  }
`;

export default NotificationSelectDD;
