import {useAuthState} from '../shared/state/useAuthState';
import {useEffect, useState} from 'react';
import { useUserState } from '../shared/state/useUserState';
import { useCookiesCustom } from '../shared/hooks/useCookiesCustom';

type TCheckSession = {
    children: React.ReactNode;
};

const CheckSession = ({children}: TCheckSession) => {
    // Компонент - обертка для проверки сессии внутри роутера

    const getUserProfileFromAPIOther = useUserState((state) => state.getUserProfileFromAPIOther);
    const isAuth = useAuthState((state) => state.isAuth);
    const logout = useAuthState((state) => state.logout);
    const serverTime = useAuthState((state) => state.serverTime);

    const {cookie, setCookie} = useCookiesCustom();

    const [resetTimestamp, setResetTimestamp] = useState<number | null>(null);

    const currentServerTimeFromCookie = cookie.currentServerTime
        ? Number(cookie.currentServerTime)
        : 0;

    const [currentServerTime, setCurrentServerTime] = useState<number>(
        currentServerTimeFromCookie || serverTime
    );

    const setIsExitUser = (value: boolean) => {
        //Записываем в куки, что пользователь вышел из браузера
        setCookie('isExitUser', value);
    };

    useEffect(() => {
        // Если пользователь закрыл браузер
        if (cookie['isExitUser'] === 'true') {
            window.addEventListener('popstate', () => {
                setIsExitUser(false);
            });
            window.addEventListener('beforeunload', () => {
                setIsExitUser(false);
            });
        }

        return () => {
            // При отмонтировании приложения
            localStorage.removeItem('redirect_url');

            window.removeEventListener('popstate', () => {
                setIsExitUser(true);
            });
            window.removeEventListener('beforeunload', () => {
                setIsExitUser(true);
            });
        };
    }, []);

    useEffect(() => {
        const savedTimestamp = cookie.refreshTokenTimestamp;
        if (parseInt(savedTimestamp) > 0) {
            setResetTimestamp(parseInt(savedTimestamp));
        }

        const handleBeforeUnload = () => {
            const ResetTimestamp = resetTimestamp ? resetTimestamp.toString() : '0';
            const CurrentServerTime = currentServerTime.toString();

            setCookie('refreshTokenTimestamp', ResetTimestamp);
            setCookie('currentServerTime', CurrentServerTime);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [
        resetTimestamp,
        cookie.refreshTokenTimestamp,
        serverTime,
        currentServerTime,
    ]);

  useEffect(() => {
    getUserProfileFromAPIOther()
  }, []);

  useEffect(() => {
      if (!isAuth && !cookie['token']) {
        logout();
      }
  }, [isAuth, cookie['token']]);


    return <>{children}</>;
};

export default CheckSession;
