import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  BagIcon,
  CommonProfileControlIcon,
  ExchangeMapRedirectIcon,
  HeadphonesIcon,
  LogoutIcon,
  TranslateIcon,
} from '../../../icons';
import { EditStaffIcon } from 'shared/components/ui/assets/svg/EditStaffIcon';
import { GearKeyclock } from 'shared/components/ui/assets/svg/GearKeyclock';
import NormalDropdown from '../../../ui/dropdown/normalDropdown';
import {
  CopyOutlined,
  PlaySquareOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import DropdownRowWithHoverIcon from '../../../ui/dropdown/dropdownRowWithHoverIcon';
import EditCompanyModal from '../modals/EditCompanyModal';
import { useTranslation } from 'react-i18next';
import { useUserState } from '../../../../state/useUserState';
import { Button, notification } from '@pankod/refine-antd';
import { useAuthState } from 'shared/state/useAuthState';
import { useNavigate } from '@pankod/refine-react-router-v6';
import { ACCOUNT_URL, MAP_URL } from 'packages/keycloak-client/constants';
import { useCookiesCustom } from '../../../../hooks/useCookiesCustom';

import './styles.css';
import { useModalState } from '../../../ui/RenderModal/model/useModalState';
import { ModalKeys } from '../../../ui/RenderModal/model/enums';
import { RenderModal } from '../../../ui/RenderModal';
const UserDropdown: FC = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const userApi = useUserState((state) => state.userApi);
  const logout = useAuthState((state) => state.logout);

  const { cookie } = useCookiesCustom();
  const openModal = useModalState((state) => state.openModal);
  const navigate = useNavigate();
  const handleEdit = () => {
    //setIsCompanyEditModal(true);
    navigate('/company');
  };
  const handleGoToMap = () => {
    window.open(MAP_URL);
  };
  const handleNavigate = () => {
    window.location.href = `${ACCOUNT_URL.replace(
      '/server',
      ''
    )}/server/account/settings?fromMenu=true`;
  };
  const languages = {
    ru: 'Русский',
    en: 'English',
    ar: 'عرب',
    fr: 'Français',
    jp: '日本語',
    pt: 'Português',
    ch: '中國人',
    es: 'Español',
    hi: 'हिंदी',
  };
  const changeLanguage = () => {
    openModal(ModalKeys.ModalChangeLanguage);
  };
  const copyContent = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(userApi.username);
        notification.success({
          message: t('shared:тексты.Скопировано'),
          className: 'dropdown-notification',
          duration: 2,
        });
      }
      console.log('this copy context is unsafe');
    } catch (err) {
      // notification.error({
      //   message: t('this copy context is unsafe'),
      //   description: t("Don't use proxy!"),
      // });
      console.error('copy error an occurred', err);
    }
  };
  const getUserId = () => userApi?.id || localStorage.getItem('userId');
  const userDD = [
    // DropdownRowWithHoverIcon({
    //   key: 'profile',
    //   isOutside: true,
    //   link: '',
    //   iconComp: () => CommonProfileControlIcon('rgba(0, 0, 0, 0.88)'),
    //   text: t('header.userMenu.profile'),
    // }),
    // DropdownRowWithHoverIcon({
    //   key: 'aboutCompany',
    //   isOutside: false,
    //   link: undefined,
    //   iconComp: () => <></>,
    //   text: userApi?.company?.name ?? '',
    //   aboutCompany: true,
    //   description: `${t('shared:тексты.Основная валюта')} ${
    //     userApi?.company?.currency_key
    //   }`,
    // }),
    // DropdownRowWithHoverIcon({
    //   key: 'aboutCompany',
    //   isOutside: false,
    //   link: undefined,
    //   onClickAction: handleEdit,
    //   iconComp: () => (
    //     <Button type="ghost" onClick={handleEdit}>
    //       {EditStaffIcon({
    //         color: '#000',
    //         height: 16,
    //         width: 16,
    //         fillOpacity: 1,
    //       })}
    //     </Button>
    //   ),
    //   text: userApi?.company?.name ?? '',
    //   aboutCompany: true,
    //   description: `${t('shared:тексты.Основная валюта')} ${
    //     userApi?.company?.currency_key
    //   }`,
    // }),
    // DropdownRowWithHoverIcon({
    //   key: 'me',
    //   isOutside: false,
    //   link: `/staff/${getUserId()}`,
    //   iconComp: () => CommonProfileControlIcon('rgba(0, 0, 0, 0.88)'),
    //   text: t('header.userMenu.settings'),
    // }),
    {
      key: 'nickname',
      label: (
        <FlexContainer $title>
          <span>{userApi.username}</span>
          <CopyOutlined
            style={{ width: '12px', height: '12px' }}
            width={'12px'}
            height={'12px'}
          />
        </FlexContainer>
      ),
      style: {
        paddingBlock: 32,
      },
      onClick: copyContent,
    },
    DropdownRowWithHoverIcon({
      isOutside: true,
      link: `/questions-answers`,
      iconComp: () => <QuestionCircleOutlined style={{ color: '#000000A6' }} />,
      text: t('shared:тексты.Вопросы и ответы'),
      key: 'questions',
    }),

    DropdownRowWithHoverIcon({
      isOutside: true,
      link: '',
      iconComp: () => <PlaySquareOutlined style={{ color: '#000000A6' }} />,
      text: t('shared:тексты.Обучающее видео'),
      key: 'guides',
      onClickAction: () =>
        (window.location.href =
          i18n.language === 'ru'
            ? 'https://www.youtube.com/watch?v=UfcGPpdXrU0'
            : 'https://www.youtube.com/watch?v=k7EAlx0W1fQ'),
    }),
    DropdownRowWithHoverIcon({
      isOutside: true,
      link: ``,
      iconComp: () => <HeadphonesIcon color={'#000000A6'} />,
      text: t('shared:тексты.Техподдержка'),
      key: 'support',
      onClickAction: () =>
        (window.location.href = 'https://t.me/+izCXhGBxF0Y0ZGQ6'),
    }),
    {
      key: 'language',
      label: (
        <FlexContainer>
          <TranslateIcon color={'#000000A6'} />
          <span>{t('shared:тексты.Язык')}</span>
          <LanguageTextStyled>
            {i18n.language.toUpperCase() +
              ' ' +
              languages[i18n.language as keyof typeof languages]}
          </LanguageTextStyled>
        </FlexContainer>
      ),
      onClick: changeLanguage,
    },
    // DropdownRowWithHoverIcon({
    //   key: 'keycloack',
    //   isOutside: false,
    //   link: undefined,
    //   onClickAction: handleNavigate,
    //   iconComp: () =>
    //     GearKeyclock({
    //       color: '#000',
    //       height: 16,
    //       width: 16,
    //       fillOpacity: 1,
    //     }),
    //   text: t('shared:тексты.Управление профилем'),
    // }),
    DropdownRowWithHoverIcon({
      key: 'exit',
      isOutside: false,
      link: ``,
      onClickAction: () => logout(),
      iconComp: () => LogoutIcon('rgba(0, 0, 0, 0.88)'),
      text: t('header.userMenu.logout'),
    }),
    // DropdownRowWithHoverIcon({
    //   key: 'goToMap',
    //   isOutside: false,
    //   link: undefined,
    //   onClickAction: handleGoToMap,
    //   iconComp: () => (
    //     <EdLogoStyled>
    //       <ExchangeMapRedirectIcon /> {'>'}
    //     </EdLogoStyled>
    //   ),
    //   text: t('shared:тексты.Веб приложения'),
    // }),
  ];
  const adminDD = [
    DropdownRowWithHoverIcon({
      key: 'aboutCompany',
      isOutside: false,
      link: undefined,
      onClickAction: handleEdit,
      iconComp: () => (
        <Button type="ghost" onClick={handleEdit}>
          {EditStaffIcon({
            color: '#000',
            height: 16,
            width: 16,
            fillOpacity: 1,
          })}
        </Button>
      ),
      text: userApi?.company?.name ?? '',
      aboutCompany: true,
      description: `${t('shared:тексты.Основная валюта')} ${
        userApi?.company?.currency_key
      }`,
    }),
    DropdownRowWithHoverIcon({
      key: 'me',
      isOutside: false,
      link: `/staff/${getUserId()}`,
      iconComp: () => CommonProfileControlIcon('rgba(0, 0, 0, 0.88)'),
      text: t('header.userMenu.settings'),
    }),
    DropdownRowWithHoverIcon({
      key: 'keycloack',
      isOutside: false,
      link: undefined,
      onClickAction: handleNavigate,
      iconComp: () =>
        GearKeyclock({
          color: '#000',
          height: 16,
          width: 16,
          fillOpacity: 1,
        }),
      text: t('shared:тексты.Управление профилем'),
    }),
    DropdownRowWithHoverIcon({
      key: 'exit',
      isOutside: false,
      link: '',
      onClickAction: () => logout(),
      iconComp: () => LogoutIcon('rgba(0, 0, 0, 0.88)'),
      text: t('header.userMenu.logout'),
    }),
    DropdownRowWithHoverIcon({
      key: 'goToMap',
      isOutside: false,
      link: undefined,
      onClickAction: handleGoToMap,
      iconComp: () => (
        <EdLogoStyled>
          <ExchangeMapRedirectIcon /> {'>'}
        </EdLogoStyled>
      ),
      text: t('shared:тексты.Веб приложения'),
    }),
  ];

  const [userDDItems, setUserDDItems] = useState<any[]>(userDD);
  const [adminDDItems, setAdminDDItems] = useState<any[]>(adminDD);

  const [isCompanyEditModal, setIsCompanyEditModal] = useState(false);

  useEffect(() => {
    setUserDDItems(userDD);
    setAdminDDItems(adminDD);
  }, [i18n.language, userApi]);

  return (
    <>
      <NormalDropdown
        isSelectable={false}
        itemWidth={284}
        items={userDDItems}
        rootClassName={'userDropdown'}
        align={{ offset: [-40, 4] }}
        content={
          <UserInfoWrapper>
            <UserInfoContainer>
              {/*<Username>{cookie['email'] || userApi?.email}</Username>*/}
              {/*<Role>*/}
              {/*  {t(*/}
              {/*    `${*/}
              {/*      userApi?.role?.name === 'admin'*/}
              {/*        ? 'shared:роли.Администратор'*/}
              {/*        : userApi?.role?.name === 'cashier'*/}
              {/*        ? 'shared:роли.Кассир'*/}
              {/*        : userApi?.role?.name === 'manager'*/}
              {/*        ? 'shared:роли.Менеджер'*/}
              {/*        : ''*/}
              {/*    }`*/}
              {/*  )}*/}
              {/*</Role>*/}
            </UserInfoContainer>
            <Avatar>
              <BagIcon />
            </Avatar>
          </UserInfoWrapper>
        }
      />
      {isCompanyEditModal && (
        <EditCompanyModal
          open={isCompanyEditModal}
          close={() => setIsCompanyEditModal(false)}
        />
      )}
      <RenderModal currentModalKey={ModalKeys.ModalChangeLanguage} />
    </>
  );
};

export { UserDropdown };

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const Avatar = styled.div<{ $mode?: string }>`
  height: 35px;
  width: 35px;
  background: var(--primary-gradient);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 7px;
`;

const Role = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
`;

const Username = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 42px;
`;

const EdLogoStyled = styled.div`
  display: flex;
  gap: 60px;
  padding: 5px 0px 20px 0px;
`;
const FlexContainer = styled.div<{ $title?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ $title }) =>
    $title &&
    css`
      font-weight: 600;
      font-size: 16px;
    `}
`;
const LanguageTextStyled = styled.span`
  display: flex;
  color: #000000a6;
  flex: 1;
  justify-content: end;
`;
