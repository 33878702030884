import {CSSProperties, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState,} from 'react';
import Styles from '../index.module.css';
import {Typography} from 'antd';
import {IUploadAttachment} from '../../../interfaces/entity';
import {MessageModalFile} from './message-modal-file';
import {PdfFile} from '../../../assets/PdfFileIcon';
import styled from 'styled-components';
import {getTime} from '../../../helpers/getTime';
import {CheckAllIcon} from '../../../assets/CheckAllIcon';
import EdenexAvatar from './assets/EdenexAvatar.svg';
import CompanyAdmin from './assets/CompanyAdmin.svg';
import Referee from './assets/Referee.svg';
import {MessageContainerContext} from '../index';

type ChatMessageFloat = 'left' | 'right';

interface IChatMessageProps extends PropsWithChildren {
    float?: ChatMessageFloat;
    participant: {
        participant_id: string;
        type: string;
        caption: string;
    }
    date: Date;
    Avatar?: JSX.Element;
    message: string;
    attachment: IUploadAttachment;
    senderNickName: string | undefined;
    messageData: any;
}

const POSITION: Record<ChatMessageFloat, CSSProperties> = {
    left: {},
    right: {flexDirection: 'row-reverse'},
};

const ChatMessage = ({
                         message,
                         date,
                         float,
                         attachment,
                         senderNickName,
                         participant,
                         messageData,
                     }: IChatMessageProps): JSX.Element => {

    const {containerRef, setPrevHeight} = useContext(MessageContainerContext);

    const [fileUrl, setFileUrl] = useState<string>('');
    const [imgModalOpen, setImgModalOpen] = useState(false);
    const messageRef = useRef<HTMLDivElement>(null);
    const time = useMemo<string>(() => {
        return getTime(date);
    }, [date]);

    const handleOpenModal = () => {
        setImgModalOpen(true);
    };

    const handleLoad = () => {
        setPrevHeight(containerRef?.current?.scrollHeight || 0);
    };

    useEffect(() => {
        async function getFile() {
            if (attachment) {
                setFileUrl(attachment?.upload_link);
            }
        }

        getFile().then();
    }, [attachment]);

    //TODO: реализовать прочитку сообщений

    // useEffect(() => {
    //   const observer = new IntersectionObserver((entries) => {
    //     entries.forEach((entry) => {
    //       if (entry.isIntersecting) {
    //         // Элемент видим
    //         // console.log('Элемент видим', message);
    //       } else {
    //         // Элемент не видим
    //         // console.log('Элемент не видим', message);
    //       }
    //     });
    //   });
    //
    //   if (messageRef.current) {
    //     observer.observe(messageRef.current);
    //   }
    //
    //   return () => {
    //     if (messageRef.current) {
    //       observer.unobserve(messageRef.current);
    //     }
    //   };
    // }, []);

    const renderAvatar = useMemo(() => {
        if (!messageData?.is_mine) {
            if (participant?.type === 'referee') {
                return <img src={Referee} alt={'Referee'}/>
            } else if (messageData?.is_system) {
                return <img src={EdenexAvatar} alt={'Admin'}/>
            } else if (participant?.type === 'exchange_point') {
                return <img src={CompanyAdmin} alt={'Company'}/>
            }
        } else if (!!messageData?.is_mine) {
            return <></>
        }
    }, [messageData?.is_mine, participant?.type])

    const renderUserName = useMemo(() => {
        if (participant.type === 'exchange_point') {
            return (
                <ExchangePointNameStyled>
                    {`Администратор ${participant?.caption}`}
                </ExchangePointNameStyled>
            )
        } else if (participant.type === 'referee') {
            return (
                <RefereeNameStyled>{`Арбитр`}</RefereeNameStyled>
            )
        }
    }, [participant.type, participant?.caption])

    const renderAttachment = useMemo(() => {
        if (!attachment?.is_uploaded) return <></>

        const attachmentText = () => {
            return attachment.file_name.length > 30
                ? attachment.file_name.substring(0, 30) + '...'
                : attachment.file_name
        }

        if (attachment?.file_extension === 'pdf') {
            return (
                <MessagePdfTextStyled>
                    <div>
                        <PdfFile/>
                    </div>
                    <MessagePdfTextStyled>
                        {attachmentText()}
                    </MessagePdfTextStyled>
                </MessagePdfTextStyled>
            )
        } else if (attachment?.file_extension === 'jpg' || attachment?.file_extension === 'png') {
            return <ImgStyled onLoad={handleLoad} src={fileUrl} alt="Attachment"/>
        } else {
            return undefined;
        }
    }, [attachment, fileUrl])

    return (
        <MessageContainerStyled
            style={{
                ...POSITION[float ?? 'left']
            }}
            ref={messageRef}
        >

            <AvatarWrapperStyled>
                {renderAvatar}
            </AvatarWrapperStyled>

            <MessageStyled $color={!!messageData?.is_mine ? 'purple' : 'default'}>
                {!!attachment && (
                    <AttachmentWrapperStyled $right={Boolean(float === 'right')}>
                        <MessageImgStyled onClick={handleOpenModal}>
                            {renderAttachment}
                        </MessageImgStyled>
                    </AttachmentWrapperStyled>
                )}
                {renderUserName}
                <Typography.Text className={Styles.message__content}>
                    {message}
                </Typography.Text>
                <div className={Styles.message__info}>
                    <MessageTimeStyled>
                        {time}
                    </MessageTimeStyled>
                    {float === 'right' &&
                        <CheckAllIcon checked/>
                    }
                </div>
            </MessageStyled>
            {attachment && (
                <MessageModalFile
                    senderNickname={senderNickName}
                    fileDownloadLink={fileUrl}
                    attachment={attachment}
                    open={imgModalOpen}
                    onClose={() => {
                        setImgModalOpen(false);
                    }}
                    date={date}
                />
            )}
        </MessageContainerStyled>
    );
};

const RefereeNameStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: #E8E264;
`;

const ExchangePointNameStyled = styled.div`
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: #5297FF;
`;

const ImgStyled = styled.img`
    @starting-animation: 0.5s ease;
    opacity: 0;
    transition: 1s ease;
`;

const MessagePdfTextStyled = styled.div`
    color: rgba(251, 251, 255, 1);
    display: flex;
    align-items: center;
    gap: 8px;
`;

const MessageTimeStyled = styled(Typography.Text)`
    font-size: 10px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: #FBFBFF;
`;

const MessageImgStyled = styled.div`
    max-width: 180px;
    max-height: 260px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
`;

const MessageContainerStyled = styled.div`
    display: flex;
    scroll-behavior: smooth;
    justify-content: flex-start;
    align-items: flex-end;
`;

const MessageStyled = styled.div<{ $color: string }>`
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 75%;
    border-radius: 8px;
    padding: 8px;
    background: ${({$color}) => ($color === 'purple' ? 'linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%)' : '#2B2B36')};
`;

const AvatarWrapperStyled = styled.div`
    width: 32px;
    margin-right: 10px;
`;

const AttachmentWrapperStyled = styled.div<{ $right: boolean }>`
    @starting-animation: 0.5s ease;
    display: flex;
    justify-content: ${({$right}) => ($right ? 'flex-end' : 'flex-start')};
    transition: 1s ease;
`;

export {ChatMessage};
