import {axios} from "../../../shared/exios";
import {API_URL} from "../../../packages/keycloak-client/constants";
import { IAccount, TAccounts } from '../model/types';

export const getAccount = async (shortName?: string): Promise<TAccounts> => {
  try {

    const payload = {
      limit: 10000,
      page: 1,
      filter: [
        {
          field: "currency.shortname",
          operator: "=",
          value: shortName
        }
      ],
      sort: [
        {
          direction: "asc",
          field: "status"
        },
        {
          direction: "asc",
          field: "shortname"
        }
      ]
    }

    if (!shortName) {
      payload.filter = [
        {
          field: 'currency.type',
          operator: '=',
          value: 'fiat'
        }
      ]
    }

    const {data} = await axios.post<{ data: IAccount[] }>(`${API_URL}/accounts/search`, payload);

    return { arr: data.data, type: 'accounts' }

  } catch {
    return { arr: [], type: 'accounts' }
  }
}
