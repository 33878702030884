import {axios} from "../../../shared/exios";
import {API_URL} from "../../../packages/keycloak-client/constants";
import {ICurrency, IExchangePoint} from "../../../interfaces";
import {TPoints} from "../model/types";
export const getExchangePoints = async (): Promise<TPoints> => {
  try {
    const res = await axios.post<{ data: IExchangePoint[]; meta: { total: number } }>(`${API_URL}/exchange-points/search`, {
      limit: 10000,
      page: 1,
    });
    return {arr: res?.data?.data, type: 'points'}
  } catch {
    return {arr: [], type: 'points'}
  }
}
