import { useCustom } from '@pankod/refine-core';
import { BackendFilters, IExchangePoint } from '../../../../../../interfaces';
import { useState } from 'react';

export const useExchangePoints = (filters?: BackendFilters[]) => {
  const [exchangePoints, setExchangePoints] = useState<IExchangePoint[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  useCustom<{
    data: IExchangePoint[];
    meta: { total: number };
  }>({
    url: '/exchange-points/search',
    method: 'post',
    config: {
      payload: {
        filters: filters ?? [],
        includes: [{ relation: 'accounts' }, { relation: 'currency' }],
      },
    },
    queryOptions: {
      onSuccess: (res) => {
        setExchangePoints(res.data.data);
        setTotalCount(res.data.meta.total);
      },
    },
  });

  return {
    exchangePoints,
    totalCount,
  };
};
