import BreadcrumbRequestPage from './BreadcrumbRequestPage';
import {PageWrapper, RequestInfoWrapper, SpinWrapperStyled} from './OfferRequestPage.styles'
import {IS_SHOW_CHAT} from "../../../packages/keycloak-client/constants";
import RequestInfo from './RequestInfo/RequestInfo';
import {useParams} from '@pankod/refine-react-router-v6';
import useOfferRequest from '../model/hooks/useOfferRequest';
import {Spin} from '@pankod/refine-antd';
import OfferRequestTabs from './offer-request-tabs/OfferRequestTabs';
import styled from 'styled-components';
import ChatOfferRequestWrapper from "../../../shared/chatWrappers/ChatOfferRequestWrapper";

const OfferRequestPage = () => {
    const {id: requestId} = useParams();

    const {
        refetch: refetchRequest,
        request,
        isLoading,
    } = useOfferRequest(Number(requestId));

    const is_buying_crypto = request.offer?.is_buying_crypto;

    const isShowChat = IS_SHOW_CHAT;

    return (
        <>
            {isLoading ? (
                <SpinWrapperStyled>
                    <Spin/>
                </SpinWrapperStyled>
            ) : (
                request && (
                    <PageCommonWrapperStyled>
                        <PageWrapper>
                            <BreadcrumbRequestPage isBuyingCrypto={is_buying_crypto}/>
                            <RequestInfoWrapper>
                                <RequestInfo
                                    request={request}
                                    refetchRequest={refetchRequest}
                                />
                                <OfferRequestTabs request={request}/>
                            </RequestInfoWrapper>
                        </PageWrapper>
                        <ChatModuleWrapperStyled>
                            {isShowChat && (
                                <ChatOfferRequestWrapper/>
                            )}
                        </ChatModuleWrapperStyled>
                    </PageCommonWrapperStyled>
                )
            )}
        </>
    );
};

const PageCommonWrapperStyled = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100%;
`;

const ChatModuleWrapperStyled = styled.div`
    height: 100%;
    width: 500px;
    margin-right: -16px;
`;

export default OfferRequestPage;
