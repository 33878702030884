import {Dropdown, Tag} from 'antd';
import React, {useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {API_URL, WS_URL,} from '../../../../../packages/keycloak-client/constants';
import {Centrifuge} from 'centrifuge';
import fb from '../../../../../packages/fb/fb';
import {ErrorBoundary} from '../../../ErrorBoundary';
import {notification} from '@pankod/refine-antd';
import {CheckCircleFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {axios} from 'shared/exios';
import {getCookieByName} from "../../../../helpers/controlCookies";
import {jwtDecode} from "jwt-decode";
import NotificationBell from './NotificationBell';
import NotificationSelectDD from "./notification-select-dd/NotificationSelectDD";
import NotificationSelectMenuItem from './NotificationSelectMenuItem';
import {useSystemNotificationsBellState} from "../../../../state/useSystemNotificationsBellState";

type TSpecialElement = {
    type: string;
    text: string;
};
export type TNotification = {
    id: number;
    user_account_id: string;
    topic: string;
    text: string;
    is_read: boolean;
    link: string | null;
    created_at: string;
    updated_at: string;
    topic_key: string;
    trigger_key: string;
    data: TSpecialElement[];
    type: string;
};

const NotificationSelect = () => {
    const centrifuge = new Centrifuge(`${WS_URL}`);
    const getNotificationSearch = useSystemNotificationsBellState((state) => state.getNotificationSearch);
    const totalCount = useSystemNotificationsBellState((state) => state.totalCount);
    const setTotalCount = useSystemNotificationsBellState((state) => state.setTotalCount);
    const notifications = useSystemNotificationsBellState((state) => state.notifications);
    const setNotifications = useSystemNotificationsBellState((state) => state.setNotifications);

    const [api, contextHolder] = notification.useNotification();

    const {t} = useTranslation(['shared']);

    const generateNotifications = useMemo(() => {
        return notifications.map((notification) => {
            return {
                key: notification.id,
                label: <NotificationSelectMenuItem item={notification}/>,
            };
        });
    }, [notifications]);

    const connectToNotificationWs = () => {
        const decodedToken: any = jwtDecode(getCookieByName('token') || '');
        if (!!decodedToken) {
            const sub = centrifuge.newSubscription(decodedToken?.sub);
            sub.on('publication', function (ctx) {
                getNotificationSearch().then();
                if (ctx?.data?.data?.model === 'popup_notifications') {
                    getPopupNotificationSearch(ctx?.data?.data?.id).then();
                }
            });
            sub.subscribe();
            centrifuge.connect();
        }
    };

    const getPopupNotificationTag = (
        debtTypes: string[],
        tagValues: string[]
    ) => {
        return debtTypes.map((debtType, index) => {
            const tagValue = tagValues[index];
            if (debtType === 'employee_debt') {
                return (
                    <Tag
                        key={index}
                        style={{
                            height: '22px',
                            display: 'inline-block',
                            margin: '0 3px 0 3px',
                        }}
                        color="red"
                    >
                        {tagValue}
                    </Tag>
                );
            }
            if (debtType === 'company_debt') {
                return (
                    <Tag
                        key={index}
                        style={{
                            height: '22px',
                            display: 'inline-block',
                            margin: '0 3px 0 3px',
                        }}
                        color="orange"
                    >
                        {tagValue}
                    </Tag>
                );
            }
            if (debtType === 'debt') {
                return <span key={index}>{t('shared:тексты.Долг').toLowerCase()}</span>;
            }
            return null; // Возможно, вам нужно добавить дополнительные условия или возврат для других значений
        });
    };

    const parseDebtDescriptionMessage = (
        description: string,
        debtTypes: string[],
        debtValues: string[]
    ) => {
        const debtTags = getPopupNotificationTag(debtTypes, debtValues);
        const parts = description.split(/(%s|\n)/);
        const result = parts.map((part: string, index: number) => {
            if (part === '%s') {
                return <React.Fragment key={index}>{debtTags.shift()}</React.Fragment>; // Используйте shift() для получения и удаления первого элемента из массива debtTags
            } else if (part === '\n') {
                return <div style={{height: '5px'}} key={index}/>;
            } else {
                return <React.Fragment key={index}>{part}</React.Fragment>;
            }
        });
        return <>{result}</>;
    };

    const getPopupNotificationSearch = async (popupId: string) => {
        try {
            const res = await axios.get(`${API_URL}/popup_notifications/${popupId}`, {
                headers: {
                    Authorization: axios.defaults.headers.common.Authorization,
                },
            });
            if (!!res?.data) {
                if (
                    res.data.trigger_key === 'create_operation_in_op_chain' ||
                    res.data.trigger_key === 'create_op_chain'
                ) {
                    api.success({
                        message: res?.data?.topic,
                        description: (
                            <>
                                {parseDebtDescriptionMessage(
                                    res?.data?.text,
                                    res?.data?.data.map((item: any) => item.type),
                                    res?.data?.data.map((item: any) => item.text)
                                )}
                            </>
                        ),
                        icon: <CheckCircleFilled style={{color: '#13C2C2'}}/>,
                        style: {borderLeft: '6px solid #13C2C2'},
                    });
                }
                if (
                    [
                        'waiting_operation',
                        'waiting_op_chain',
                        'close_operation',
                        'close_op_chain',
                        'cancel_operation',
                        'cancel_op_chain',
                        'edit_op_chain',
                    ].includes(res.data.trigger_key)
                ) {
                    api.info({
                        message: res?.data?.topic,
                        description: (
                            <>
                                {parseDebtDescriptionMessage(
                                    res?.data?.text,
                                    res?.data?.data.map((item: any) => item.type),
                                    res?.data?.data.map((item: any) => item.text)
                                )}
                            </>
                        ),
                        style: {borderLeft: '6px solid #1677FF'},
                    });
                }
                return;
            }
        } catch (e) {
            console.log('getNotificationSearch', e);
        }
    };

    useEffect(() => {
        connectToNotificationWs();
        const deleteNotificationFromUnreadListener = fb.addListener(
            'deleteNotificationFromUnread',
            (id: number) => {
                getNotificationSearch().then();
                setNotifications(notifications.filter((item) => item.id !== id));

                if (totalCount > 0) {
                    setTotalCount(totalCount - 1);
                } else {
                    setTotalCount(totalCount);
                }

            }
        );

        const setAllNotificationsIsReadListener = fb.addListener(
            'setAllNotificationsIsRead',
            () => {
                setNotifications([]);
                setTotalCount(0);
            }
        );

        return () => {
            deleteNotificationFromUnreadListener.remove();
            setAllNotificationsIsReadListener.remove();
            centrifuge.disconnect()
        };
    }, []);

    return (
        <ErrorBoundary>
            {contextHolder}
            <DropdownStyled
                trigger={['click']}
                menu={{items: generateNotifications}}
                placement="bottom"
                overlayClassName={'notification-dropdown'}
                dropdownRender={(menu) => <NotificationSelectDD item={menu}/>}
            >
                <NotificationSelectTriggerStyled onClick={(e) => e.preventDefault()}>
                    <NotificationBell total={totalCount}/>
                </NotificationSelectTriggerStyled>
            </DropdownStyled>
        </ErrorBoundary>
    );
};

const DropdownStyled = styled(Dropdown)`
    display: flex;
    justify-items: center;
    top: 0 !important;
`;

const NotificationSelectTriggerStyled = styled.a`
    display: flex;
    justify-content: center;
`;

export default NotificationSelect;
