import {axios} from "../../../shared/exios";
import {API_URL, MAP_API_URL} from "../../../packages/keycloak-client/constants";
import {ICurrency} from "../../../interfaces";
import {TCurrencies, TCurrency} from "../model/types";

export const getCurrencies = async (): Promise<TCurrencies[]> => {
  try {
    const {data} = await axios.post<{ data: TCurrency[]  ; meta: { total: number } }>(`${MAP_API_URL}/payment-system-currency/search`, {
      limit: 10000,
      page: 1,
    }, {});
    const fiat: TCurrency[] = []
    const digital: TCurrency[] = []
    data?.data.forEach(curr => {
      if (curr.currency_type === 'fiat' && curr.type === 'cash') {
        fiat.push(curr)
      }
      if (curr.currency_type === 'digital' && curr.type === 'crypto') {
        digital.push(curr)
      }
    })
    return [
      {
        arr: fiat,
        type: 'fiat'
      },
      {
        arr: digital,
        type: 'digital'
      }
    ]
  } catch {
    return [{
      arr: [],
      type: 'fiat'
    },
      {
        arr: [],
        type: 'digital'
      }]
  }
}
