import {useParams} from "@pankod/refine-react-router-v6";
import {useMessagesState} from "../modules/ChatModule/states/useMessagesState";
import useOfferRequest from "../../pages/offer-request/model/hooks/useOfferRequest";
import {useEffect} from "react";
import {useChatTokensState} from "../state/useChatTokensState";
import {Centrifuge} from "centrifuge";
import {WS_URL} from "../../packages/keycloak-client/constants";
import {useCookiesCustom} from "../hooks/useCookiesCustom";
import ChatModule from "../modules/ChatModule/ChatModule";
import {calcAuthToken} from "../modules/ChatModule/helpers/calcAuthToken";

const ChatOfferRequestWrapper = () => {
    const {id: requestId} = useParams();
    const {cookie, setCookie} = useCookiesCustom();
    const phexChat = useMessagesState((state) => state.phexChat);
    const getPhexChat = useMessagesState((state) => state.getPhexChat);
    const getPhexChatToken = useChatTokensState((state) => state.getPhexChatToken);
    const getChatInfo = useMessagesState((state: { getChatInfo: any; }) => state.getChatInfo);
    const chatInfo = useMessagesState((state: { chatInfo: any; }) => state.chatInfo);
    const opponent = chatInfo?.participants?.find((participant: { is_opponent: any; }) => !!participant?.is_opponent);
    const wssChannels = useChatTokensState((state) => state.wssChannels);
    const getWSChatToken = useChatTokensState((state) => state.getWSChatToken);
    const searchMessages = useMessagesState((state) => state.searchMessages);
    const totalMessages = useMessagesState((state) => state.totalMessages);
    const currentPage = useMessagesState((state) => state.currentPage);
    const isFetchingMessages = useMessagesState((state) => state.isFetchingMessages);
    const messageLimit = useMessagesState((state) => state.messageLimit);
    const setIsFetchingMessages = useMessagesState((state) => state.setIsFetchingMessages);
    // const addMessageToMessages = useMessagesState((state) => state.addMessageToMessages);
    const messages = useMessagesState((state) => state.messages);
    const createAttachment = useMessagesState((state) => state.createAttachment);
    const addMessage = useMessagesState((state) => state.addMessage);
    const changeIsUploadInAttachment = useMessagesState((state) => state.changeIsUploadInAttachment);
    const uploadAttachmentToMinio = useMessagesState((state) => state.uploadAttachmentToMinio);
    const resetFields = useMessagesState((state) => state.resetFields);

    const { request} = useOfferRequest(Number(requestId));

    useEffect(() => {
        getPhexChatToken({setCookie}).then();
        getPhexChat({
            offer_deal_id: String(requestId),
        }).then(() => {
            searchMessages({
                entity_type: 'offer_deal',
                entity_id: String(requestId),
                client_uid: request?.author_uid,
                auth_token: calcAuthToken({chatType: 'phex'})
            }).then();
        });
    }, [request?.author_uid, request?.id]);

    useEffect(() => {

      resetFields()

        if (request?.author_uid && requestId) {
            getChatInfo({
                entity_type: 'offer_deal',
                entity_id: String(requestId),
                client_uid: request?.author_uid,
                auth_token: calcAuthToken({chatType: 'phex'})
            }).then()
        }
    }, [request?.author_uid, requestId])

    const centrifuge = new Centrifuge(WS_URL, {
        token: cookie['wsChatToken'],
        getToken: async () => {
            getWSChatToken().then();
            return cookie['wsChatToken'];
        }
    });

    useEffect(() => {
        async function subscribeToChannel({channelName}: { channelName: string }) {
            const chatUserSub = centrifuge.newSubscription(channelName);
            chatUserSub.on('publication', function (pubData) {
                if (!channelName.includes('$chat-user')) {
                    // const {data} = pubData;
                    // if (data?.type === 'new_message') {
                    // addMessageToMessages(data?.message);
                    // }
                    searchMessages({
                        entity_type: 'offer_deal',
                        entity_id: String(requestId),
                        client_uid: request?.author_uid,
                        auth_token: calcAuthToken({chatType: 'phex'})
                    }).then();
                }
            }).subscribe();
        }

        getWSChatToken().then(async () => {
            if (!!wssChannels.length) {
                for (const channelName of wssChannels) {
                    await subscribeToChannel({channelName})
                }
            }
        }).finally(() => {
            centrifuge.connect();
        });

        return () => {
            centrifuge.disconnect();
        }
    }, [])


    useEffect(() => {
        if ((messageLimit * currentPage < totalMessages || currentPage === 1) && request?.author_uid) {
            if (requestId) {
                searchMessages({
                    entity_type: 'offer_deal',
                    entity_id: String(requestId),
                    client_uid: request?.author_uid,
                    auth_token: calcAuthToken({chatType: 'phex'})
                }).then()
            }
        }
    }, [currentPage, totalMessages, messageLimit, requestId, request?.author_uid]);


    return (
        <ChatModule
            isShowTimer={false}
            isClosableChat={false}
            currentChat={phexChat}
            isReferee={phexChat?.has_referee}
            setIsOpenChat={() => {
            }}
            opponent={opponent}
            currentPage={currentPage}
            setIsFetchingMessages={setIsFetchingMessages}
            searchMessages={searchMessages}
            messageLimit={messageLimit}
            totalMessages={totalMessages}
            isFetchingMessages={isFetchingMessages}
            messages={messages}
            addMessage={addMessage}
            changeIsUploadInAttachment={changeIsUploadInAttachment}
            createAttachment={createAttachment}
            uploadAttachmentToMinio={uploadAttachmentToMinio}
            calcEntityId={() => String(requestId)}
            entity_type={'offer_deal'}
            client_uid={phexChat?.author_uid}
            typeTabs={''}
            chatType={'phex'}
        />
    )
}

export default ChatOfferRequestWrapper;
