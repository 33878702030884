import React from 'react';
import {
  AcceptButtonInDropdownStyled,
  ButtonInDropdownStyled,
  CancelButtonInDropdownDisabledStyled,
  CancelButtonInDropdownStyled,
  CenteredStatusCaseStyle,
  EmptyOffersStyled,
} from './DealsTable.styles';
import {TDeals} from '../../../pages/offers/model/types';
import {ColumnsType} from 'antd/es/table';
import {useTranslation} from 'react-i18next';
import HeaderCellDescription from '../../../shared/components/ui/table/cells/HeaderCellDescription';
import CellDescription from '../../../shared/components/ui/table/cells/CellDescription';
import {IExchangePoint} from '../../../interfaces';
import {DealsStatus} from './DealsStatus';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import {MenuProps} from 'antd/es/menu/menu';
import {TableStyled} from '../../../styles';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {Dropdown} from '@pankod/refine-antd';
import {MoreOutlined} from '../../../shared/components/icons';
import {ModalKeys} from '../../../shared/components/ui/RenderModal/model/enums';
import {useModalState} from '../../../shared/components/ui/RenderModal/model/useModalState';
import {RenderModal} from '../../../shared/components/ui/RenderModal';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {reorderArray} from '../../../shared/helpers/reorderArray';
import {ACCOUNT_URL} from '../../../packages/keycloak-client/constants';

type modalPropsType = {
    id: number;
    isBuyingCrypto: boolean;
    name: string;
    status?: string;
    currency?: string;
    refetchData: () => void;
};

interface DealsTableProps {
    deals: TDeals[];
    points: IExchangePoint[];
    refetchDeals: () => void;
    pagination?: {
        current: number;
        total: number;
        pageSize: number;
    };
    setPagination?: (pagination: number) => void;
    callbacks?: {
        onCancel: (
            id: number,
            isReversed: boolean,
            cryptoCurrencyCode: string
        ) => void;
    };
    isDealsEmpty: boolean;
    isFullTable?: boolean;
}

const DealsTable = (props: DealsTableProps) => {
    const {
        deals,
        points,
        setPagination,
        pagination,
        callbacks,
        isDealsEmpty,
        refetchDeals,
        isFullTable = true,
    } = props;

    const {t} = useTranslation(['offers', 'shared']);

    const {openModal} = useModalState();

    const navigate = useNavigate();
    const handleChange = (pageInfo: { current?: number; pageSize?: number }) => {
        setPagination?.(pageInfo?.current ?? 1);
    };

    const dropdownClickHandler = (
        modalKey: 'CancelTransaction' | 'ConfirmReceiptOfCash' | 'OpenDispute',
        deal: TDeals,
        isBuyingCrypto: boolean = false
    ) => {
        let modalProps: modalPropsType = {
            id: deal.id,
            isBuyingCrypto,
            name: deal.name,
            refetchData: refetchDeals,
        };

        let modalKeyMapping = {
            ConfirmReceiptOfCash: ModalKeys.ModalConfirmReceiptOfCash,
            CancelTransaction: ModalKeys.ModalCancelTransaction,
            OpenDispute: ModalKeys.ModalOpenDispute,
        };

        switch (modalKey) {
            case 'CancelTransaction': {
                modalProps.status = deal.status;
                break;
            }
            case 'OpenDispute': {
                modalProps.currency = 'USDT';
                break;
            }
        }
        openModal(modalKeyMapping[modalKey], modalProps);
    };

    const handleRedirectToConfirm = (id: number) => {
        navigate(`/offers/confirm-request/${id}`);
    };

    const getDropdownActionsHandler = (record: TDeals): MenuProps['items'] => {
        const getCancelLabel = () =>
            t(`${record.status === 'pending' ? 'Отклонить' : 'Отменить'} сделку`);

        let items: ItemType[] = [];

        reorderArray(record?.allowed_statuses).forEach((status) => {

            if (record.status === 'warranty_collateral' && !record.offer?.is_buying_crypto) {
                items.push({
                    key: '34',
                    label: (
                        <ButtonInDropdownStyled
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(ACCOUNT_URL + '/coin-accounts')
                            }}
                        >
                            <WalletOutlined/>
                            &nbsp; {t('shared:кнопки.Пополнить баланс')}
                        </ButtonInDropdownStyled>
                    )
                })
            }

            switch (status) {
                case 'completed': {
                    items.push({
                        key: '1',
                        label: (
                            <AcceptButtonInDropdownStyled
                                onClick={() =>
                                    dropdownClickHandler(
                                        'ConfirmReceiptOfCash',
                                        record,
                                        record.offer?.is_buying_crypto
                                    )
                                }
                            >
                                <CheckOutlined/>
                                &nbsp; {t('Получил наличные')}
                            </AcceptButtonInDropdownStyled>
                        ),
                    });
                    break;
                }
                case 'on_argument': {
                    items.push({
                        key: '2',
                        label: (
                            <ButtonInDropdownStyled
                                onClick={() =>
                                    dropdownClickHandler(
                                        'OpenDispute',
                                        record,
                                        record.offer?.is_buying_crypto
                                    )
                                }
                            >
                                <ExclamationCircleOutlined/>
                                &nbsp; {t('Открыть спор')}
                            </ButtonInDropdownStyled>
                        ),
                    });
                    break;
                }
                case 'accepted': {
                    items.push({
                        key: '4',
                        label: (
                            <AcceptButtonInDropdownStyled
                                onClick={() =>
                                    handleRedirectToConfirm(record.id)
                                }
                            >
                                <CheckOutlined/>
                                &nbsp; {t('shared:кнопки.Принять сделку')}
                            </AcceptButtonInDropdownStyled>
                        ),
                    });
                    break;
                }
                case 'expired':
                case 'cancelled': {
                    items.push({
                        key: '3',
                        label: (
                            <CancelButtonInDropdownStyled
                                onClick={() =>
                                    dropdownClickHandler(
                                        'CancelTransaction',
                                        record,
                                        record.offer?.is_buying_crypto
                                    )
                                }
                            >
                                <CloseOutlined/>
                                &nbsp; {getCancelLabel()}
                            </CancelButtonInDropdownStyled>
                        ),
                    });
                    break;
                }
            }
        });

        if (record?.status === 'ready' && !record?.allowed_statuses.includes('cancelled') && !record?.allowed_statuses.includes('expired') && !record.offer?.is_buying_crypto) {
            items.push({
                key: '354',
                label: (
                    <CancelButtonInDropdownDisabledStyled
                        onClick={(e) => e.stopPropagation()}
                    >
                        <CloseOutlined/>
                        &nbsp; {t('Отменить сделку')}
                    </CancelButtonInDropdownDisabledStyled>
                )
            })
        }

        return items;
    };

    const offersColumns: ColumnsType<TDeals> = [
        {
            title: ' ',
            dataIndex: 'status',
            key: 'status',
            width: '3%',
            render: (_, record) => (
                <CenteredStatusCaseStyle>
                    <DealsStatus status={record.status}/>
                </CenteredStatusCaseStyle>
            ),
        },
        {
            title: () => (
                <HeaderCellDescription
                    title={
                        isFullTable
                            ? t('shared:тексты.Оффер')
                            : `ID ${t('shared:тексты.Сделки')}`
                    }
                    description={isFullTable ? `ID ${t('shared:тексты.Сделки')}` : ''}
                />
            ),
            dataIndex: 'a',
            key: 'a',
            width: 48,
            render: (_, record) => {
                const currency =
                    (record?.offer?.is_buying_crypto
                        ? t('shared:тексты.Покупка')
                        : t('shared:тексты.Продажа')) +
                    ' ' +
                    record?.offer?.crypto_currency_code;
                const dealId = record.name;

                return (
                    <CellDescription
                        titleLink={
                            isFullTable
                                ? `/offers/${record?.offer?.id}`
                                : `/offers/request/${record?.id}`
                        }
                        description={isFullTable ? dealId : undefined}
                        title={isFullTable ? currency : dealId}
                    />
                );
            },
        },
        {
            title: () => (
                <HeaderCellDescription
                    description={''}
                    title={t('shared:тексты.Объем актива')}
                />
            ),
            dataIndex: 'b',
            key: 'b',
            width: 48,
            render: (_, record) => {
                const currency =
                    record.crypto_amount?.replace('.', ',') +
                    ' ' +
                    record?.offer?.crypto_currency_code?.replace('TRC20', '');
                return <CellDescription title={currency}/>;
            },
        },
        {
            title: () => (
                <HeaderCellDescription
                    description={''}
                    title={t('shared:тексты.Курс клиента')}
                />
            ),
            dataIndex: 'c',
            key: 'c',
            width: 48,
            render: (_, record) => {
                const currency =
                    record.client_rate?.replace('.', ',') +
                    ' ' +
                    record?.offer?.cash_currency_code?.replace('CASH', '');
                return <CellDescription title={currency}/>;
            },
        },
        {
            title: () => (
                <HeaderCellDescription
                    description={''}
                    title={t('shared:тексты.Объем фиата')}
                />
            ),
            dataIndex: 'c',
            key: 'c',
            width: 48,
            render: (_, record) => {
                const currency =
                    record.cash_amount?.replace('.', ',') +
                    ' ' +
                    record?.offer?.cash_currency_code?.replace('CASH', '');
                return <CellDescription title={currency}/>;
            },
            onCell: () => ({
                onClick: (event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                },
            }),
        },
        {
            title: () => (
                <HeaderCellDescription
                    description={''}
                    title={t('shared:тексты.Клиент')}
                />
            ),
            dataIndex: 'c1',
            key: 'c1',
            width: 48,
            render: (_, record) => {
                return <CellDescription title={record.nickname}/>;
            },
            // onCell: () => ({
            //   onClick: (event: React.SyntheticEvent) => {
            //     event.preventDefault();
            //     event.stopPropagation();
            //   },
            // }), // TODO: add link on client
        },
        {
            title: '',
            dataIndex: 'd',
            key: 'd',
            width: '5%',
            render: (_, record) => {
                if (record.allowed_statuses.length) {
                    return (
                        <Dropdown
                            menu={{
                                items: getDropdownActionsHandler(record) as MenuProps['items'],
                            }}
                        >
                            {MoreOutlined()}
                        </Dropdown>
                    );
                }
            },
            onCell: () => ({
                onClick: (event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                },
            }),
        },
    ];

    if (isFullTable) {
        offersColumns.splice(-1, 0, {
            title: () => (
                <HeaderCellDescription
                    description={''}
                    title={t('shared:тексты.Пункт обмена')}
                />
            ),
            dataIndex: 'c2',
            key: 'c2',
            width: 48,
            render: (_, record) => {
                const pointTitle =
                    points?.find(
                        (point) => point.id === record?.offer?.exchange_point?.id
                    )?.name ?? t('shared:тексты.Имя пункта обмена недоступно');
                const city = record?.offer?.city;
                return (
                    <CellDescription
                        titleLink={
                            points?.find(
                                (point) => point.id === record?.offer?.exchange_point?.id
                            )
                                ? '/points/' + record?.offer?.exchange_point?.id
                                : undefined
                        }
                        title={pointTitle}
                    />
                );
            },
            onCell: () => ({
                onClick: (event: React.SyntheticEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                },
            }),
        });
    }

    const handleRow = (val: TDeals) => {
        if (isFullTable) {
            navigate(`/offers/request/${val?.id}`);
        }
    };

    return (
        <>
            {isDealsEmpty ? (
                <EmptyOffersStyled>
                    <InboxOutlined/>
                    {t('shared:тексты.Нет сделок')}
                </EmptyOffersStyled>
            ) : (
                <div>
                    {deals?.length ? (
                        <TableStyled
                            onRow={(record: TDeals) => {
                                return {
                                    onClick: () => handleRow(record as TDeals),
                                };
                            }}
                            pagination={
                                pagination?.total && pagination?.total > 10
                                    ? {
                                        current: pagination?.current,
                                        total: pagination?.total,
                                        hideOnSinglePage: true,
                                        showSizeChanger: false,
                                    }
                                    : false
                            }
                            columns={offersColumns as ColumnsType<object>}
                            dataSource={deals}
                            onChange={handleChange}
                        ></TableStyled>
                    ) : (
                        <EmptyOffersStyled>
                            <InboxOutlined/>
                            {t('shared:тексты.Нет сделок')}
                        </EmptyOffersStyled>
                    )}
                </div>
            )}
            <RenderModal currentModalKey={ModalKeys.ModalConfirmReceiptOfCash}/>
            <RenderModal currentModalKey={ModalKeys.ModalOpenDispute}/>
            <RenderModal currentModalKey={ModalKeys.ModalCancelTransaction}/>
        </>
    );
};

export default DealsTable;
