import { OfferFilters } from 'features/OfferFilters';
import { useOffersState } from '../model/useOffersState';
import React, { useEffect, useState } from 'react';
import { getCurrencies } from '../api/getCurrencies';
import { getExchangePoints } from '../api/getExchangePoints';
import { useTranslation } from 'react-i18next';
import { Button } from '@pankod/refine-antd';
import { ContainerStyled, HeaderStyled, TitleStyled } from './Offers.styles';
import { NavigationTabs } from './NavigationTabs';
import { useNavigationState } from '../model/useNavigationState';
import { OffersTable } from '../../../features/OffersTable';
import { getOffers } from '../api/getOffers';
import { PlusOutlined } from '@ant-design/icons';
import { ModalKeys } from '../../../shared/components/ui/RenderModal/model/enums';
import { RenderModal } from '../../../shared/components/ui/RenderModal';
import { useModalState } from '../../../shared/components/ui/RenderModal/model/useModalState';
import { TCurrency, TNavigation } from '../model/types';
import { useDealsState } from '../model/useDealsState';
import { getAccount } from '../api/getAccount';
import { getClients } from '../api/getClients';
import { getDeals } from '../api/getDeals';
import CreateOfferBtn from './CreateOfferBtn';

import DealsTable from '../../../features/DealsTable/ui/DealsTable';

const OffersPage = ({ type }: { type: TNavigation }) => {
  const { t } = useTranslation(['offers', 'shared']);
  const {
    directionFilters,
    pointsFilters,
    ccyCurrFilters,
    fiatCurrFilters,
    statusFilters,
    setFilter,
    setFilterInitial,
    resetAll,
    setCost,
    setFilterCollapsed,
    setIsCollapsed,
    isFixCost,
    isFiltersCollapsed,
    setOffers,
    meta,
    setMeta,
    offers,
    points,
    activeFilters,
    recalcActiveFilters,
  } = useOffersState();

  const {
    directionFilters: directionFiltersDeals,
    pointsFilters: pointsFiltersDeals,
    cryptoCurrFilters: cryptoCurrFiltersDeals,
    fiatCurrFilters: fiatCurrFiltersDeals,
    clientsFilters,
    accountsFilters,
    statusFilters: statusFiltersDeals,
    setFilter: setFilterDeals,
    setFilterInitial: setFilterInitialDeals,
    resetAll: resetAllDeals,
    setCost: setCostDeals,
    setFilterCollapsed: setFilterCollapsedDeals,
    setIsCollapsed: setIsCollapsedDeals,
    isFixCost: isFixCostDeals,
    isFiltersCollapsed: isFiltersCollapsedDeals,
    setDeals,
    meta: metaDeals,
    setMeta: setMetaDeals,
    deals,
    points: pointsDeals,
    activeFilters: activeFiltersDeals,
    recalcActiveFilters: recalcActiveFiltersDeals,
  } = useDealsState();

  const [forceOffers, setForceOffers] = useState(false);
  const [forceDeals, setForceDeals] = useState(false);
  const [firstRenderDeals, setFirstRenderDeals] = useState(true);
  const [isOffersEmpty, setIsOffersEmpty] = useState(false);
  const [isDealsEmpty, setIsDealsEmpty] = useState(false);
  const [cryptoCurrencies, setCryptoCurrencies] = useState<TCurrency[]>([]);
  const handleForce = () => {
    setForceOffers((prev) => !prev);
  };
  const { activeTab, setActiveTab } = useNavigationState();
  const { openModal } = useModalState();
  const handleOpenModalCancel = (
    id: number,
    isBuyingCrypto: boolean,
    cryptoCurrencyCode: string
  ) => {
    const currency = cryptoCurrencies.find(
      (currency) => currency.code === cryptoCurrencyCode
    )?.currency_key;
    openModal(ModalKeys.ModalCancelOffer, {
      id: id,
      isBuyingCrypto: isBuyingCrypto,
      cryptoCurrencyCode: currency,
      callback: handleForce,
    });
  };

  useEffect(() => {
    (async () => {
      if (type === 'offers') {
        const data = await Promise.all([getCurrencies(), getExchangePoints()]);
        setCryptoCurrencies(data[0][1].arr);
        setFilterInitial(data.flat());
      } else {
        const data = await Promise.all([
          getCurrencies(),
          getExchangePoints(),
          getClients(),
        ]);

        setFilterInitialDeals(data.flat());
      }
    })();
  }, [type]);

  useEffect(() => {
    (async () => {
      const dataDeals = await getAccount(
        activeFiltersDeals.find((el) => el.field === 'offer.cash_currency_code')
          ?.value as string | undefined
      );
      setFilterInitialDeals([dataDeals]);
    })();
  }, [
    activeFiltersDeals.find((el) => el.field === 'offer.cash_currency_code')
      ?.value,
  ]);

  useEffect(() => {
    (async () => {
      const dataOffers = await getOffers(
        activeFilters as [],
        meta.current_page
      );
      setOffers(dataOffers);
      if (offers?.length !== 0) {
        setIsOffersEmpty(false);
      }
    })();
  }, [activeFilters, meta.current_page, forceOffers]);

  const fetchDeals = async () => {
    const dealsData = await getDeals(
      activeFiltersDeals as [],
      metaDeals.current_page
    );
    setDeals(dealsData);
  };

  useEffect(() => {
    if (type === 'offers') return;

    (async () => {
      await fetchDeals();

      if (!deals?.length && firstRenderDeals) {
        setIsOffersEmpty(true);
      }
      if (deals?.length !== 0) {
        setFirstRenderDeals(false);
        setIsDealsEmpty(false);
      }
    })();
  }, [activeFiltersDeals, metaDeals.current_page, forceDeals]);

  useEffect(() => {
    recalcActiveFilters();
  }, [
    directionFilters,
    pointsFilters,
    ccyCurrFilters,
    fiatCurrFilters,
    statusFilters,
    isFixCost,
  ]);

  useEffect(() => {
    recalcActiveFiltersDeals();
  }, [
    directionFiltersDeals,
    pointsFiltersDeals,
    cryptoCurrFiltersDeals,
    fiatCurrFiltersDeals,
    statusFiltersDeals,
    clientsFilters,
    accountsFilters,
  ]);
  const handleCreate = () => {};
  return (
    <ContainerStyled>
      <HeaderStyled>
        <TitleStyled>
          {type === 'offers'
            ? t('shared:тексты.Офферы')
            : t('shared:тексты.Сделки')}
        </TitleStyled>
        {type === 'offers' && <CreateOfferBtn />}
      </HeaderStyled>
      <NavigationTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setIsCollapsed={
          type === 'offers' ? setIsCollapsed : setIsCollapsedDeals
        }
        isFiltersCollapsed={
          type === 'offers' ? isFiltersCollapsed : isFiltersCollapsedDeals
        }
      />
      {type === 'offers' ? (
        <OfferFilters
          isFiltersCollapsed={isFiltersCollapsed}
          filters={[
            {
              options: directionFilters,
              nameDefaultProp: t('shared:тексты.Все направления'),
              label: t('shared:тексты.Направление оффера'),
              type: 'directionFilters',
            },
            {
              options: statusFilters,
              nameDefaultProp: t('shared:тексты.Все статусы'),
              label: t('shared:тексты.Статус оффера'),
              type: 'statusFilters',
            },
            {
              options: ccyCurrFilters,
              label: t('shared:тексты.Актив'),
              type: 'ccyCurrFilters',
              isDisabled: true,
            },
            {
              options: fiatCurrFilters,
              nameDefaultProp: t('shared:тексты.Все валюты'),
              label: t('shared:тексты.Фиат'),
              type: 'fiatCurrFilters',
              search: true,
            },
            {
              options: pointsFilters,
              nameDefaultProp: t('shared:тексты.Все пункты'),
              label: t('shared:тексты.Пункты обмена'),
              type: 'pointsFilters',
              isMultiple: true,
              search: true,
            },
          ]}
          setFilter={setFilter}
          resetAll={resetAll}
          checkboxes={[
            {
              name: t('shared:тексты.Без фикс точка стоимости'),
              value: isFixCost,
              onChange: setCost,
            },
          ]}
          setFilterCollapsed={setFilterCollapsed}
        />
      ) : (
        <OfferFilters
          isFiltersCollapsed={isFiltersCollapsedDeals}
          filters={[
            {
              options: directionFiltersDeals,
              nameDefaultProp: t('shared:тексты.Все направления'),
              label: t('shared:тексты.Направление оффера'),
              type: 'directionFilters',
            },
            {
              options: statusFiltersDeals,
              nameDefaultProp: t('shared:тексты.Все статусы'),
              label: t('Статус сделки'),
              type: 'statusFilters',
              isMultiple: true,
              search: true,
            },
            {
              options: pointsFiltersDeals,
              nameDefaultProp: t('shared:тексты.Все пункты'),
              label: t('shared:тексты.Пункты обмена'),
              type: 'pointsFilters',
              isMultiple: true,
              search: true,
            },
            {
              options: cryptoCurrFiltersDeals,
              label: t('shared:тексты.Актив'),
              type: 'ccyCurrFilters',
              isDisabled: true,
            },
            {
              options: clientsFilters,
              nameDefaultProp: t('shared:тексты.Все клиенты'),
              label: t('shared:тексты.Клиент'),
              type: 'clientsFilters',
              isMultiple: true,
              search: true,
            },
            {
              options: fiatCurrFiltersDeals,
              nameDefaultProp: t('shared:тексты.Все валюты'),
              label: t('shared:тексты.Фиат'),
              type: 'fiatCurrFilters',
              search: true,
            },
            {
              options: accountsFilters,
              nameDefaultProp: t('shared:тексты.Все счета'),
              label: t('Счет для наличных'),
              type: 'accountsFilters',
              isMultiple: true,
              search: true,
            },
          ]}
          setFilter={setFilterDeals}
          resetAll={resetAllDeals}
          setFilterCollapsed={setFilterCollapsedDeals}
        />
      )}

      {type === 'offers' ? (
        <OffersTable
          pagination={{
            current: meta.current_page,
            total: meta.total,
            pageSize: meta.per_page,
          }}
          setPagination={setMeta}
          offers={offers}
          points={points}
          callbacks={{
            onCancel: handleOpenModalCancel,
          }}
          isOffersEmpty={isOffersEmpty}
        />
      ) : (
        <DealsTable
          refetchDeals={fetchDeals}
          pagination={{
            current: metaDeals.current_page,
            total: metaDeals.total,
            pageSize: metaDeals.per_page,
          }}
          setPagination={setMetaDeals}
          deals={deals}
          points={pointsDeals}
          callbacks={{
            onCancel: handleOpenModalCancel,
          }}
          isDealsEmpty={isDealsEmpty}
        />
      )}
      <RenderModal currentModalKey={ModalKeys.ModalCancelOffer} />
    </ContainerStyled>
  );
};
export default OffersPage;
