import {devtools} from 'zustand/middleware';
import create from 'zustand';
import {axios} from '../exios';
import {CHAT_URL, EXCHANGE_MAP_API_URL, FINMS_URL_API} from "../../packages/keycloak-client/constants";
import {getCookieByName, setCookieByName} from "../helpers/controlCookies";
import {produce} from "immer";

type TChatTokensState = {
    wssChannels: string[];
    getWSChatToken: () => Promise<void>;
    getPhexChatToken: ({setCookie}: TGetPhexChatToken) => Promise<void>
    getPartnerChatToken: ({setCookie}: TGetPartnerChatToken) => Promise<void>
};

type TGetPhexChatToken = {
    setCookie: (name: string, value: string) => void
}

type TGetPartnerChatToken = {
    setCookie: (name: string, value: string) => void
}

export const useChatTokensState = create<TChatTokensState>()(
    devtools(
        (set) => ({
            wssChannels: [],
            getWSChatToken: async () => {
                try {
                    const res = await fetch(`${CHAT_URL}/ws/token`, {
                        method: 'get',
                        headers: {
                            Authorization: `Bearer ${getCookieByName('phexChatToken')?.replace(
                                'Bearer',
                                ''
                            )}`
                        }
                    })
                    const data = await res.json();
                    setCookieByName('wsChatToken', data?.token);
                    set(
                        produce((draft) => {
                            draft.wssChannels = data?.channels;
                        })
                    )

                } catch (e) {
                    console.error('getWSChatToken error', e);
                }
            },
            getPhexChatToken: async ({setCookie}: TGetPhexChatToken) => {
                try {
                    const res = await axios.get(`${FINMS_URL_API}/server/api/chats/get-token`)
                    setCookie('phexChatToken', res?.data?.data?.access_token)
                } catch (e) {
                    // @ts-expect-error
                    console.error('getPhexChatToken error:', e?.response?.data?.message)
                }
            },
            getPartnerChatToken: async ({setCookie}: TGetPartnerChatToken) => {
                console.log('getPartnerChatToken EXCHANGE_MAP_API_URL', EXCHANGE_MAP_API_URL)
                try {
                    const res = await axios.get(`${EXCHANGE_MAP_API_URL}/partner/api/chats/get-token`)
                    setCookie('partnerChatToken', res?.data?.data?.access_token)
                } catch (e) {
                    // @ts-expect-error
                    console.error('getPartnerChatToken error:', e?.response?.data?.message)
                }
            },
        }), {
            anonymousActionType: 'useChatTokensState action',
            name: 'useChatTokensState',
        }),
);
