import {Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {ChatHeaderInfoTempTimerStyled} from "./assets/styles";
import dayjs from "dayjs";
import {useMemo} from "react";

type TChatHeaderInfo = {
    created_at: string | null;
}

const ChatHeaderInfo = ({created_at}: TChatHeaderInfo) => {
    const {t} = useTranslation();

    const calcDeadline = useMemo(() => {
        if (created_at) {
            const a = dayjs(created_at);
            const b = a.add(1, 'day');

            if (a < b) {
                return b.format();
            } else {
                return undefined;
            }
        }
    }, [created_at]);


    const renderChatInfo = () => {
        if (created_at) {
            return (
                <Tooltip
                    trigger={'hover'}
                    title={t('shared:чат.Таймер тултип')}
                >
                    <ChatHeaderInfoTempTimerStyled
                        value={calcDeadline}
                        format={`H ${t('shared:чат.ч')} : mm ${t('shared:чат.м')} ${t('shared:чат.временный чат')}`}
                    />
                </Tooltip>
            )
        } else {
            return <></>
        }
    }

    return (
        <>{renderChatInfo()}</>
    )
}

export default ChatHeaderInfo;
