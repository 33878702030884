import {
  AccountReportType,
  UserReportType,
} from '../../pages/DailyReports/interface';
import {
  AdditionalCurrencyType,
  IExchangePoint,
} from '../../pages/ExchangePoints/interface';
import { CourseType } from '../../pages/currencyPairs/CourseType';
import { AccountType } from '../../pages/accountsList/AccountType';
import { IExchangePointReport } from '../interfaces';
import { OperationChainTypeReportsType } from '../types';

export const DEFAULT_NUMBER = 1;

export const DEFAULT_SEARCH_PAGE_AMOUNT = 15;

export const DEFAULT_PAGE_AMOUNT = 10;

export const DEFAULT_DASH = '-';

export const COURSE_INT_PART = 10;
export const COURSE_DEC_PART = 10;
export const COURSE_DEC_PART_HIGH_PRECISION = 18;

export const DEFAULT_EXCHANGE_POINT_ACCOUNT_REPORT: AccountReportType = {
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  exchange_point_report_id: DEFAULT_NUMBER,
  account_id: DEFAULT_NUMBER,
  main_currency_beginning_day_balance: '',
  main_currency_end_day_balance: '',
  account_currency_beginning_day_balance: '',
  account_currency_end_day_balance: '',
  end_day_balance_diff: null,
  beginning_day_balance_diff: null,
  course: '',
  created_at: '',
  updated_at: '',
};

export const DEFAULT_EXCHANGE_POINT_USER_REPORT: UserReportType = {
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  exchange_point_report_id: DEFAULT_NUMBER,
  user_account_id: '',
  closed_chains_number: '',
  pending_chains_number: '',
  canceled_chains_number: '',
  arrival_without_mmf: '',
  expenditure_without_mmf: '',
  created_at: '',
  updated_at: '',
};

export const DEFAULT_EXCHANGE_POINT: IExchangePoint = {
  id: DEFAULT_NUMBER,
  name: '',
  address: '',
  timezone: '',
  daily_report_time: '',
  currency_id: DEFAULT_NUMBER,
  status: 'not_working',
  published_status: 'not_published',
  monitoring_status: 'disabled',
  is_publish_request_sent: false,
  accounts_balance: '',
  company_id: DEFAULT_NUMBER,
  created_at: '',
  updated_at: '',
};

export const DEFAULT_CURRENCY = {
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  name: '',
  shortname: '',
  zeros_number: '',
  status: '',
  type: '',
  created_at: '',
  updated_at: '',
};

export const DEFAULT_ADDITIONAL_CURRENCY: AdditionalCurrencyType = {
  id: DEFAULT_NUMBER,
  shortName: '',
  zeros_number: DEFAULT_NUMBER,
  key: DEFAULT_NUMBER,
};

export const DEFAULT_CURRENCY_REPORT = {
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  exchange_point_report_id: DEFAULT_NUMBER,
  currency_id: DEFAULT_NUMBER,
  end_day_balance: '',
  created_at: '',
  updated_at: '',
};

export const DEFAULT_ACCOUNT: AccountType = {
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  currency_id: DEFAULT_NUMBER,
  type: 'bank',
  name: '',
  shortname: '',
  requisites: '',
  balance: '',
  min_balance: '',
  max_balance: '',
  status: '',
  created_at: '',
  updated_at: '',
  convert_main: '',
  is_external: false,
};

export const DEFAULT_USER_ACCOUNT = {
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  role_id: DEFAULT_NUMBER,
  username: '',
  firstname: '',
  lastname: '',
  phone_number: '',
  status: '',
  contact_info: '',
  created_at: '',
  updated_at: '',
};

export const DEFAULT_ROLE = {
  id: '',
  name: '',
  description: '',
  company_id: DEFAULT_NUMBER,
  created_at: '',
  updated_at: '',
};

export const DEFAULT_COURSE: CourseType = {
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  primary_currency_key: '',
  minimal_ratio: '',
  minimal_ratio_percent: '',

  secondary_currency_key: '',
  actually_ratio: '',
  recommended_ratio: '',
  recommended_ratio_percent: '',

  updated_at: '',
  external_ratio: '',
  external_updated_at: '',
  actually_updated_at: '',
  recommended_updated_at: '',
  minimal_updated_at: '',
};
export const DEFAULT_EXCHANGE_POINT_REPORT: IExchangePointReport = {
  key: DEFAULT_NUMBER,
  id: DEFAULT_NUMBER,
  company_id: DEFAULT_NUMBER,
  exchange_point_id: DEFAULT_NUMBER,
  exchange_chains_arrival: '',
  exchange_chains_expenditure: '',
  bidding_chains_arrival: '',
  bidding_chains_expenditure: '',
  mmf_chains_arrival: '',
  mmf_chains_expenditure: '',
  arrival: '',
  expenditure: '',
  arrival_status: 'calculated',
  expenditure_status: 'calculated',
  accounts_balance: '',
  created_at: '',
  updated_at: '',
  has_balance_diff: false,
  exchange_point_user_reports: [DEFAULT_EXCHANGE_POINT_USER_REPORT],
  exchange_point: DEFAULT_EXCHANGE_POINT,
  exchange_point_account_reports: [DEFAULT_EXCHANGE_POINT_ACCOUNT_REPORT],
  currency: DEFAULT_CURRENCY,
  is_updated: false,
};

export const DEFAULT_OPERATION_CHAIN_TYPE_REPORT: OperationChainTypeReportsType =
  {
    id: DEFAULT_NUMBER,
    company_id: DEFAULT_NUMBER,
    exchange_point_report_id: DEFAULT_NUMBER,
    arrival_status: 'calculated',
    expenditure_status: 'calculated',
    operations_chain_type_id: DEFAULT_NUMBER,
    arrival: '',
    expenditure: '',
    created_at: '',
    updated_at: '',
  };
