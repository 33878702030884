import Styles from './index.module.css';
import React, {useMemo, useRef, useState} from 'react';
import {Spin} from 'antd';
import styled from 'styled-components';
import {ChatListMessages} from './list-messages';

interface IChatContext {
    setPrevHeight: (value: number) => void;
    containerRef: React.RefObject<HTMLDivElement> | null;
    prevHeight: number;
}

export const MessageContainerContext = React.createContext<IChatContext>({
    setPrevHeight: () => {
    },
    containerRef: null,
    prevHeight: 0,
});

type TChatMessageContainer = {
    currentPage: number,
    setIsFetchingMessages: (value: boolean) => void,
    messageLimit: number,
    totalMessages: number,
    isFetchingMessages: boolean,
    messages: any[],
}

const ChatMessageContainer = ({
                                  currentPage,
                                  setIsFetchingMessages,
                                  messageLimit,
                                  totalMessages,
                                  isFetchingMessages,
                                  messages
                              }: TChatMessageContainer): JSX.Element => {

    const containerRef = useRef<HTMLDivElement>(null);
    const [prevHeight, setPrevHeight] = useState(0);

    const scrollHandler = (e: any) => {
        if (e.target.scrollTop === 0) {
            setIsFetchingMessages(true);
        }
    };

    const renderListMessage = useMemo(() => {
        if (isFetchingMessages || currentPage * messageLimit < totalMessages) {
            return (
                <SpinnWrapper>
                    <Spin size={'large'}/>
                </SpinnWrapper>
            )
        } else {
            return <ChatListMessages messages={messages}/>
        }
    }, [isFetchingMessages, currentPage, messageLimit, totalMessages, messages, messages?.length])

    return (
        <MessageContainerContext.Provider
            value={{setPrevHeight, containerRef, prevHeight}}
        >
            <div
                ref={containerRef}
                onScroll={scrollHandler}
                className={Styles.container}
            >
                {renderListMessage}
            </div>
        </MessageContainerContext.Provider>
    );
};

const SpinnWrapper = styled.div`
    display: flex;
    justify-content: center;

    .ant-spin-dot-item {
        background: #1668DC;
    }
`;

export {ChatMessageContainer};
