import { BellOutlined } from '@ant-design/icons';
import { Badge } from '@pankod/refine-antd';
import styled from 'styled-components';
import { ErrorBoundary } from '../../../ErrorBoundary';
import { Suspense } from 'react';

const NotificationBell = ({ total }: { total: number }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<>Loading...</>}>
        <BadgeStyled
          offset={[4, 0]}
          count={total}
          size={'default'}
          color={'#13C2C2'}
          showZero={true}
        >
          <BellOutlinedStyled size={20} />
        </BadgeStyled>
      </Suspense>
    </ErrorBoundary>
  );
};

const BellOutlinedStyled = styled(BellOutlined)`
  height: 20px;
  width: 20px;
  direction: ltr;
  color: #ffffffcc;
`;

const BadgeStyled = styled(Badge)`
  direction: ltr;
`;

export default NotificationBell;
