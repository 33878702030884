import {Tag} from '@pankod/refine-antd';
import {Link} from '@pankod/refine-react-router-v6';
import {useOperationsFiltersState} from '../../../shared/state/filtersState/useOperationsFiltersState';
import styled from 'styled-components';

function JsxCreator(items: { type: string; text: string }[], link: string, topic_key: string) {
    const setOperationsFilters = useOperationsFiltersState(
        (state) => state.setOperationsFilters
    );

    return items?.length && items?.map((item) => {
        if (item.type === 'link') {
            if (items[items.length - 1].type === 'exPointId') {
                return (
                    <Link
                        onClick={() => {
                            setOperationsFilters(
                                {first_exchange_point_id: [+items[items.length - 1].text]},
                                true
                            );
                        }}
                        to={link}
                    >
                        {item.text}
                    </Link>
                );
            }

            //@ts-ignore
            if (topic_key === 'exchange') {
                return <a href={link}>{item.text}</a>;
            }

            return <Link to={link}>{item.text}</Link>;
        }

        if (item.type === 'corporate' || item.type === 'accountTypes') {
            return (
                <DefaultTag>
                    {item.text}
                </DefaultTag>
            );
        }
        if (
            item.type === 'opChainStatusWaiting' ||
            item.type === 'operationStatusWaiting'
        ) {
            return <OrangeTag>{item.text}</OrangeTag>;
        }
        if (
            item.type === 'opChainStatusClosed' ||
            item.type === 'operationStatusClosed'
        ) {
            return <CyanTag>{item.text}</CyanTag>;
        }
        if (
            item.type === 'opChainStatusCanceled' ||
            item.type === 'operationStatusCanceled'
        ) {
            return <RedTag>{item.text}</RedTag>;
        }
        return <></>;
    });
}

export const useParser = (
    original: string,
    values: { type: string; text: string }[] | string,
    link: string,
    topic_key: string
) => {

    let valuesData = values;

    // Проверяем, является ли values строкой
    if (typeof values === 'string') {
        // Проверяем, содержит ли строка валидный JSON
        try {
            // Если строка начинается с [ и заканчивается на ], пытаемся разобрать
            if (values.trim().startsWith('[') && values.trim().endsWith(']')) {
                valuesData = JSON.parse(values);
            } else {
                valuesData = []; // Можно вернуть пустой массив или что-то более подходящее
            }
        } catch (error) {
            valuesData = []; // Обработка ошибки парсинга
        }
    }

    // Проверяем, является ли valuesData массивом
    if (!Array.isArray(valuesData)) {
        return {texts: [''], elems: []};
    }

    const textArr = original.split('%s');
    const arr = JsxCreator(valuesData, link, topic_key);

    return {texts: textArr, elems: arr};
};

const RedTag = styled(Tag)`
    border-color: #58181C;
    background-color: #2A1215;
    color: #D32029;
`
const OrangeTag = styled(Tag)`
    border-color: #593815;
    background-color: #2B1D11;
    color: #D87A16;
`
const CyanTag = styled(Tag)`
    border-color: #144848;
    background-color: #112123;
    color: #13A8A8;
`
const DefaultTag = styled(Tag)`
    border-color: #474755;
    background-color: #2B2B36;
    color: #FFFFFFD9;
`

